<script>
import axios from "axios";
import TableKelompok from "./table-kelompok.vue";

/**
 * Dashboard Component
 */
export default {
  props: ["id_simulasi", "nama_simulasi","intake_type"],
  components: {
    TableKelompok,
  },
  data() {
    return {
      data_kelompok: [],
      data_batch: [],
      data_jadwal_batch: [],
      data_peserta: [],
    };
  },
  mounted() {
    this.getPeserta();
    this.getKelompok();
  },
  methods: {
    getKelompok() {
      const apiUrl =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        `monitoring/data-kelompok/${this.$route.params.id}/${this.id_simulasi}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var responseData = response.data;
          this.data_kelompok = responseData.data.data.kelompok;
          this.data_batch = responseData.data.data.batch;
          this.data_jadwal_batch = this.data_batch.t_jadwal_batch;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    getPeserta() {
      const apiUrl =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        `monitoring/peserta-by-asesor/${this.$route.params.id}/${this.id_simulasi}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var responseData = response.data;
          this.data_peserta = responseData.data.data;
          console.log(this.data_peserta);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <h4>{{ nama_simulasi }}</h4>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <div class="d-flex align-items-center">
        <!-- <img
          src="http://dac.alfahuma.com/backend/storage/icon-simulasi-config/icon-simulasi-config-20230907_05_07_10.jpg"
          alt="Dummy Image"
          width="100"
          height="100"
          class="mr-3"
        /> -->
      </div>
    </div>
  </div>
  <hr />
  <div class="row mt-2" v-for="(kelompok, key) in data_kelompok" :key="key">
    <TableKelompok
      :kelompok="kelompok"
      :id_simulasi="id_simulasi"
      :intake_type="intake_type"
      
    />
  </div>
  <!-- <div class="row mt-2">
    <table class="table mb-0 table-bordered table-hover">
      <thead class="bg-dark text-white">
        <tr>
          <th class="p-2">Kelompok</th>
          <th class="p-2">Pin</th>
          <th class="p-2">Nip</th>
          <th class="p-2">Nama</th>
          <th class="p-2">Status Evidence</th>
          <th class="p-2">Evidence</th>
          <th class="p-2">Last Update</th>

          <th class="p-2">Aksi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(asesi, key) in data_peserta" :key="key">
          <td class="p-2">{{ asesi.kelompok }}</td>
          <td class="p-2">{{ asesi.pin }}</td>
          <td class="p-2">{{ asesi.nip_asesi }}</td>
          <td class="p-2">{{ asesi.nama_asesi }}</td>
          <td class="p-2">{{ asesi.status_evidence }}</td>
          <td class="p-2">{{ asesi.persen_evidence }}</td>
          <td class="p-2">-</td>
          <td class="p-2">
            <div class="btn-group">
              <button type="button" class="btn btn-primary btn-sm">
                <i class="fas fa-plus me-2"></i>Input Evidence
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div> -->
</template>

<style>
/* Add custom CSS for styling the items */
.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Add some vertical spacing between items */
}

.label {
  width: 100px; /* Fixed width for labels */
  text-align: left; /* Align labels to the left */
  margin-right: 10px; /* Add some right margin between label and colon */
}

.colon {
  width: 10px; /* Fixed width for colons */
  text-align: center; /* Center-align the colons */
  margin-right: 5px; /* Add some right margin between colon and value */
}

.value {
  flex: 1; /* Allow the value to take up remaining space */
}
</style>
