<script>
import axios from "axios";
export default {
  props: ["kelompok", "id_simulasi", "intake_type"],
  components: {},
  data() {
    return {
      data_kelompok: [],
      user_profile: null,
      data_jadwal: null,
      data_user : JSON.parse(localStorage.user)
    };
  },
  mounted() {
    this.getProfile();
    this.getKelompokPeserta();
    this.getDataJadwal();
  },
  methods: {
    getProfile() {
      let self = this;
      let config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/users/" +
          localStorage.session_user_id +
          "/profile",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config).then((response) => {
        let response_data = response.data.data;
        self.user_profile = response_data.master_user;
      });
    },
    getDataJadwal() {
      const apiUrl =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        `monitoring/data-jadwal/${this.$route.params.id}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var responseData = response.data;
          this.data_jadwal = responseData.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    getKelompokPeserta() {
      const apiUrl =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        `monitoring/kelompok-peserta/${this.$route.params.id}/${this.id_simulasi}/${this.kelompok}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var responseData = response.data;
          this.data_kelompok = responseData.data.data;
          this.data_kelompok = this.data_kelompok.filter(entry => entry.asesor_id == this.data_user.master_user_id);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    redirectHalamanEvidence(asesi) {
      let self = this;
      let last_data = "";
      if (
        self.$props.intake_type == "intaketype4" ||
        self.$props.intake_type == "intaketype7"
      ) {
        last_data = "#";
      } else {
        last_data = "#readmore";
      }
      let url =
        "http://" +
        process.env.VUE_APP_OASE_EVIDENCE_ASESOR_URL +
        "?id_jadwal_oase2=" +
        asesi.jadwal_id +
        "&nip_asesi=" +
        asesi.nip_asesi +
        "&dirkom_nama=" +
        encodeURI(self.data_jadwal?.j_txt_dirkom) +
        "&id_simulasi=" +
        asesi.simulasi_id +
        "&nip_asesor=" +
        self.user_profile?.u_nip +
        "&nama_asesor=" +
        encodeURI(localStorage.session_name) +
        last_data;
      window.open(url, "_blank");
    },
  },
};
</script>
<template>
  <div class="table-responsive mt-2">
    <h6>{{ kelompok }}</h6>
    <table class="table mb-0 table-bordered table-hover">
      <thead class="bg-dark text-white">
        <tr>
          <th class="p-2">Pin</th>
          <th class="p-2">Nip</th>
          <th class="p-2">Nama</th>
          <th class="p-2">Asesor</th>
          <th class="p-2">Status Pengerjaan</th>
          <th class="p-2">Sisa Waktu</th>

          <th class="p-2">Aksi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(asesi, key) in data_kelompok" :key="key">
          <td class="p-2">{{ asesi.pin }}</td>
          <td class="p-2">{{ asesi.nip_asesi }}</td>
          <td class="p-2">{{ asesi.nama_asesi }}</td>
          <td class="p-2">{{ asesi.asesor_master.u_nama }}</td>
          <td class="p-2">
            <b-badge
              v-if="asesi.status == 'berlangsung'"
              pill
              variant="warning"
            >
              {{ asesi.status }}
            </b-badge>
            <b-badge v-else-if="asesi.status == 'belum'" pill variant="danger">
              {{ asesi.status }}
            </b-badge>
            <b-badge v-else pill variant="success">
              {{ asesi.status }}
            </b-badge>
          </td>
          <td class="p-2">
            {{ asesi.durasi ? asesi.durasi - asesi.durasi_terpakai : 0 }} menit
          </td>
          <td class="p-2">
            <div class="btn-group">
              <router-link
                :to="{
                  name: 'monitoring-admin-detail',
                  params: {
                    id_simulasi: asesi.simulasi_id,
                    nip_asesi: asesi.nip_asesi,
                    intake_type: intake_type,
                    id_jadwal_detail: asesi.id,
                    posisi : 'Asesor'
                  },
                }"
                class="btn btn-info btn-sm"
                ><i class="fas fa-eye"></i> Detail</router-link
              >
              <!-- <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="redirectHalamanEvidence(asesi)"
              >
                <i class="fas fa-undo"></i> Evidence
              </button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
